<template>
    <div class="main-wrapper" v-if="settings">
        <notifications width="25rem" position="top right"  />
        <router-view name="header"></router-view>
        <router-view></router-view>
        <router-view name="footer"></router-view>
    </div>
</template>

<script>
import '@/css/main.min.css';
import '@/css/additional.css';
import {computed} from "@vue/runtime-core";
import {store} from '@/store';

export default {
    name: 'App',
  setup() {
    const settings = computed(() => {
      return store.getters['settings/getSettings'].settings
    })
    return {settings}
  }
};
</script>
<style>
    .btn-secondary {
        border-color: #ddd;
    }
    .btn-secondary:hover {
        border-color: #ddd;
    }
</style>

