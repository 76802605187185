<template>
    <div></div>
<!--    <footer class="footer">-->
<!--        <div class="container-fluid">-->
<!--            <div class="row">-->
<!--                <div class="col">© Doxcase</div>-->
<!--                <div class="col-auto"><router-link to="#"> OKTA DIGITAL </router-link></div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </footer>-->
</template>
<script>
export default {
    name: 'FooterComponent',
};
</script>
