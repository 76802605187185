<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="(item, i) of list" :key="i" class="breadcrumb-item" itemprop="itemListElement">
        <a v-if="item.link" :href="item.link" itemprop="item">
          <span itemprop="name">{{ item.name }}</span>
          <meta itemprop="position" :content="i + 1"/>
        </a>
        <a v-else itemprop="item">
          <span itemprop="name">{{ item.name }}</span>
          <meta itemprop="position" :content="i + 1"/>
        </a>
      </li>
    </ol>
  </nav>
</template>

<script>
import {computed} from "@vue/runtime-core";

import {store} from '@/store';

export default {
  props: {
    list: Array,
  },
  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {colors}
  },
};
</script>
<style scoped>
a {
  color: v-bind(colors.primary);
}
</style>
