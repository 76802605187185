import {axiosInstance} from '@/utils/API';

class settingsService {
    getSettings = async () => {
        const res = await axiosInstance.get('/settings');
        if (res.data) {
            return res.data;
        } else throw new Error('Error fetching settings');
    };
    updateSettings = async (settings) => {
        const res = await axiosInstance.patch('/settings', settings);
        if (res.data) {
            return res.data;
        } else throw new Error('Error fetching settings');
    };
}
export default new settingsService();
