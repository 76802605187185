<template>
    <div class='logo-wrapper'>
        <img
            alt='doxcase logo'
            src='/img/doxcase_big.svg'
            :height="iconHeight"
            :width="iconWidth"
            class="logo"
        >
    </div>

<!--    <svg-->
<!--        :width="iconWidth"-->
<!--        class="logo"-->
<!--        :height="iconHeight"-->
<!--        :viewBox="viewBoxValue"-->
<!--        fill="none"-->
<!--        xmlns="http://www.w3.org/2000/svg"-->
<!--    >-->
<!--        <path-->
<!--            d="M0.0351562 137.721C0.0351562 126.713 8.12896 118.451 19.0358 118.451C29.9427 118.451 37.927 126.713 37.927 137.721C37.927 148.729 29.8332 157 19.0358 157C8.23845 157 0.0351562 148.738 0.0351562 137.721ZM31.3913 137.721C31.3913 130.327 26.0516 124.717 19.0358 124.717C12.0201 124.717 6.56242 130.327 6.56242 137.721C6.56242 145.116 11.9106 150.708 19.0358 150.708C26.1611 150.708 31.3913 145.125 31.3913 137.721Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path-->
<!--            d="M74.8505 156.453H66.5967L51.3692 139.019V156.453H45.002V118.999H51.3692V135.136L66.2682 118.999H74.2946L57.8375 136.753L74.8505 156.453Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path d="M108.919 118.999V125.038H96.2351V156.453H89.8679V125.038H77.125V118.999H108.919Z" :fill="iconColor" />-->
<!--        <path-->
<!--            d="M135.776 156.453L132.592 147.87H117.449L114.266 156.453H107.469L121.787 118.999H128.272L142.59 156.453H135.776ZM119.639 141.933H130.436L125.038 127.312L119.639 141.933Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path-->
<!--            d="M171.495 118.999C182.284 118.999 191.245 125.147 191.245 137.721C191.245 150.296 182.284 156.453 171.495 156.453H161.936V118.999H171.495ZM171.495 154.078C180.616 154.078 188.71 148.957 188.71 137.721C188.71 126.486 180.616 121.365 171.495 121.365H164.412V154.078H171.495Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path d="M202.59 118.999V156.453H200.105V118.999H202.59Z" :fill="iconColor" />-->
<!--        <path-->
<!--            d="M227.908 139.128V136.753H242.588V153.648C240.483 155.434 236.33 156.891 231.639 156.891C221.28 156.891 211.426 150.305 211.426 137.722C211.426 125.796 220.977 118.561 231.016 118.561C236.684 118.561 240.575 120.557 242.681 122.562L241.241 124.6C238.42 122.184 234.805 120.897 231.092 120.987C222.291 120.987 213.978 127.084 213.978 137.772C213.978 148.46 222.459 154.448 231.665 154.448C234.608 154.497 237.513 153.771 240.087 152.343V139.12L227.908 139.128Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path d="M254.793 118.999V156.453H252.309V118.999H254.793Z" :fill="iconColor" />-->
<!--        <path d="M292.853 118.999V121.365H278.653V156.453H276.126V121.365H261.977V118.999H292.853Z" :fill="iconColor" />-->
<!--        <path-->
<!--            d="M321.842 156.453L317.252 144.906H299.565L294.975 156.453H292.271L307.229 118.999H309.605L324.554 156.453H321.842ZM300.525 142.531H316.334L308.434 122.561L300.525 142.531Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path d="M334.045 154.078H353.964V156.453H331.561V118.999H334.045V154.078Z" :fill="iconColor" />-->
<!--        <path-->
<!--            d="M183.616 78.5129H130.657V13.8635H143.931V0.63208H130.657H125.317H117.426V91.7443H124.551H130.657H183.616H194.843H196.839V27.0865H183.616V78.5129Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--        <path-->
<!--            d="M157.152 0.63208V9.5597V13.8635V65.2899H170.384V13.8635H223.343V78.5129H210.069V91.7443H223.343H236.574V78.5129V13.8635V9.5597V0.63208H157.152Z"-->
<!--            :fill="iconColor"-->
<!--        />-->
<!--    </svg>-->
</template>
<script>
import {toRefs} from 'vue';
export default {
    props: {
        iconColor: {
            type: String,
            default: '#fff',
        },
        iconWidth: {
            type: String,
            required: true,
        },
        iconHeight: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const {iconWidth, iconHeight} = toRefs(props);
        const viewBoxValue = `0 0 ${iconWidth.value} ${iconHeight.value}`;
        const styleObject = {
            maxWidth: iconWidth,
            maxHeight: iconHeight
        }

        return {
            viewBoxValue,
            styleObject
        };
    },
};
</script>
<style scoped>
.logo-wrapper {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    margin-bottom: 40px;
}
@media (max-width: 974px) {
    .logo {
        width: 100%;
    }
}
</style>
