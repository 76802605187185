<template>
    <label class="search-radio__label"
    >
        <input
            :value="section?.id"
            @change="$emit('update:modelValue', $event.target.value)"
            :checked="section?.id === modelValue"
            type="radio"
            class="btn-check"
        />
        <span class="btn btn-info btn-sm">{{ section?.title}}</span>
    </label>
</template>

<script>
import {computed} from "vue";
import {store} from '@/store';

export default {
props: {
    section: {
        type: Object,
        default:() => ({
            id: '',
            title: 'Все'
        })
    },
    modelValue: {
        type: String,
        default: null
    }
},

  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
      colors,
    }
  }
};
</script>

<style scoped>
.search-radio__label {
  margin-right: 10px;
    margin-bottom: 10px;
}
.btn-info {
  background-color: v-bind(colors.secondary) !important;
  border-color: v-bind(colors.secondary) !important;
  color: v-bind(colors.primary) !important;
}
.btn-check:checked~.btn-info {
  background-color: v-bind(colors.primary) !important;
  border-color: v-bind(colors.primary) !important;
}
</style>
