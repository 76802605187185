<template>
    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4632 5.71452L10.2133 0.71452C10.0796 0.573431 9.8878 0.495133 9.68833 0.500235H2.18845C1.36004 0.500235 0.688477 1.13983 0.688477 1.92881V19.0717C0.688477 19.8607 1.36004 20.5002 2.18845 20.5002H14.1883C15.0167 20.5002 15.6882 19.8607 15.6882 19.0717V6.21452C15.6936 6.02454 15.6114 5.84185 15.4632 5.71452ZM9.68843 2.21451L13.8884 6.21451H9.68843V2.21451ZM2.18834 1.9288V19.0717H14.1881V7.64309H9.68822C8.8598 7.64309 8.18824 7.0035 8.18824 6.21452V1.9288H2.18834Z"
            :fill="colors.primary"
        />
    </svg>
</template>

<script>
import {store} from '@/store';
import {computed} from "vue";

export default {
  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
      colors,
    }
  },
};
</script>
