<template>
  <div>
    <div class="row s-logo">
      <div class="mb-2">Логотип</div>
      <div class="col-md-4 s-border">
        <img :src="src" alt="" class="s-img">
      </div>
      <div class="col-md-4 align-self-center">
        <UploaderLogoImage
            v-model="fileInput"
        >
        </UploaderLogoImage>
      </div>
    </div>
<!--          <input type="file" accept="image/*" @change="log($event)" id="file-input" ref="img-file">-->
    <div class="row mb-2">
      <div class="mb-2">
        Основной цвет
      </div>
      <div :style="{
        backgroundColor: colorPrimary,
        height: '50px',
        width: '50px',
      }"
           @click="isPrimaryColorModalVisible = !isPrimaryColorModalVisible"
      >
      </div>
      <div class="col-auto align-self-center"
           @click="isPrimaryColorModalVisible = !isPrimaryColorModalVisible"
      >
        {{ colorPrimary }}
      </div>
    </div>
    <div class="row mb-4"
    >
      <div class="mb-2">
        Второй цвет
      </div>
      <div class="col-auto" :style="{
        backgroundColor: colorSecondary,
        height: '50px',
        width: '50px'
      }"
           @click="isSecondaryColorModalVisible = !isSecondaryColorModalVisible"
      >
      </div>
      <div class="col-auto align-self-center"
           @click="isSecondaryColorModalVisible = !isSecondaryColorModalVisible"
      >
        {{ colorSecondary }}
      </div>
    </div>
<!--    <div class="container-fluid d-flex">-->
<!--      <VButton class="btn-save" @click="save">Сохранить</VButton>-->
<!--    </div>-->
    <div class="sAddDocs__footer user-groups-footer-buttons">
      <div class="container-fluid d-flex">
        <VButton class="btn-save" @click="save"> Сохранить изменения</VButton>
        <VButton class="ms-2" outline> Отмена </VButton>
      </div>
    </div>
    <modal-window
        v-model="isPrimaryColorModalVisible"
        maxWidth="300px"
    >
      <div class="modal-window__header">
        <h3>Основной цвет</h3>
      </div>
      <ColorPicker
          style="box-sizing: content-box;"
          theme="light"
          :color="colorPrimary"
          :sucker-hide="true"
          @changeColor="changePrimaryColor"
          class="mb-4"
      />
      <VButton class="btn-save" @click="isPrimaryColorModalVisible = !isPrimaryColorModalVisible">Сохранить</VButton>
    </modal-window>
    <modal-window
        v-model="isSecondaryColorModalVisible"
        maxWidth="300px"
    >
      <div class="modal-window__header">
        <h3>Второй цвет</h3>
      </div>
      <ColorPicker
          style="box-sizing: content-box;"
          theme="light"
          :color="colorSecondary"
          :sucker-hide="true"
          @changeColor="changeSecondaryColor"
          class="mb-4"
      />
      <VButton class="btn-save" @click="isSecondaryColorModalVisible = !isSecondaryColorModalVisible">Сохранить</VButton>
    </modal-window>
  </div>
</template>

<script>
import {ColorPicker} from 'vue-color-kit'
// import 'vue-color-kit/dist/vue-color-kit.css'
import VButton from '@/ui/VButton';
import {axiosInstance} from '@/utils/API';
import UploaderLogoImage from "../../components/UploaderLogoImage";
import {store} from '@/store';
import ModalWindow from '@/components/ModalWindow';

export default {
  components: {
    UploaderLogoImage,
    VButton,
    ColorPicker,
    ModalWindow,
  },
  data: () => ({
    // settings: null,
    fileInput: null,
    preview: null,
    colorPrimary: '#ff00ff',
    colorSecondary: '#ff00ff',
    isPrimaryColorModalVisible: false,
    isSecondaryColorModalVisible: false,
    isLoading: false,
  }),
  computed: {
    src() {
      // if (this.settings?.logo) {
      //   return this.settings?.logo
      // }
      if (this.fileInput) {
        return window.URL.createObjectURL(this.fileInput);
      }
      return this.settings.logo;
    },
    settings() {
      return this.$mySettings.settings
    }
  },
  mounted() {
    // console.log(this.$mySettings)
    // this.getSettings()
    this.colorPrimary = this.$mySettings.settings.colors.primary
    this.colorSecondary = this.$mySettings.settings.colors.secondary
  },
  methods: {
    log(e) {
      this.fileInput = e.target.files[0]
    },
    changePrimaryColor(color) {
      this.colorPrimary = color.hex
    },
    changeSecondaryColor(color) {
      this.colorSecondary = color.hex
    },
    save() {
      this.isLoading = true
      const formData = new FormData();

      if (this.fileInput) {
        formData.append('logo', this.fileInput)
      }

      let colors = {
        primary: this.colorPrimary,
        secondary: this.colorSecondary,
      }
      for (let key in colors) {
        formData.append(`colors[${key}]`, colors[key])
      }

      formData.append('title', 'title')

      axiosInstance.post('/settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        store.dispatch('settings/fetchSettingsData', true)
        this.fileInput = null
        this.isLoading = false
      }).then(() => {
        // this.$forceUpdate();
        // this.$router.go(this.$router.currentRoute)
      }).catch((e) => {
        console.log(e)
      })

    }
  }

};
</script>

<style scoped>
.s-logo {
  max-height: 179px;
  height: 179px;
  margin-bottom: 4rem;
}

.s-border {
  max-height: 155px;
  height: 155px;
  border: 2px solid;
  border-color: lightgray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.s-img {
  object-fit: contain;
  max-width: 200px;
  max-height: 155px
}
.btn-save {
  background-color: v-bind(settings.colors.primary);
  border-color: v-bind(settings.colors.primary);
  color: #ffffff;
}
.btn-save:hover {
  background-color: v-bind(settings.colors.hover);
  border-color: v-bind(settings.colors.hover);
  color: #ffffff;
}
.btn-save:focus {
  box-shadow: 0 0 0 0.25rem v-bind(settings.colors.shadow);
}
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem v-bind(settings.colors.shadow);
}
.btn-outline-primary:active {
  background-color: v-bind(settings.colors.primary);
  color: #ffffff;
}
.sAddDocs__footer {
  position: absolute;
  bottom: 33px;
}
.sAddDocs__footer {
  margin-bottom: -25px;
  margin-left: -32px;
  padding-left: 35px;
}

</style>
