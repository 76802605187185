<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6885 16.8564L16.6885 11.8564H13.6885V4.85645H11.6885V11.8564H8.68848L12.6885 16.8564Z"
            :fill="colors.primary"
        />
        <path
            d="M20.6885 18.8564H4.68848V11.8564H2.68848V18.8564C2.68848 19.9594 3.58548 20.8564 4.68848 20.8564H20.6885C21.7915 20.8564 22.6885 19.9594 22.6885 18.8564V11.8564H20.6885V18.8564Z"
            :fill="colors.primary"
        />
    </svg>
</template>

<script>
import {store} from '@/store';
import {computed} from "vue";

export default {
  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
      colors,
    }
  },
};
</script>
