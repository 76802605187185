<template>
    <div>
        <label class="checkbox-input__container">
            <input
                class="form-check-input checkbox-input"
                name="checkbox"
                type="checkbox"
                @change="changeValue"
                :checked="modelValue"
                v-bind="$attrs"
            />
            <span class="checkbox-input__text">
                <slot></slot>
            </span>
        </label>
    </div>
</template>

<script>
import {computed} from "vue";
import {store} from '@/store';

export default {
    props: {
        modelValue: Boolean,
        disabled: Boolean,
    },
    setup(props, ctx) {
        const changeValue = (e) => {
            ctx.emit('update:modelValue', e.target.checked);
        };

        const colors = computed(() => {
          return store.getters['settings/getSettings'].settings.colors
        })
        return {
            changeValue,
            colors,
        };
    },
};
</script>

<style scoped>
.checkbox-input__container {
    display: flex;
    min-height: 1.5rem;
    padding-left: 1.5em;
    cursor: pointer;
}

.checkbox-input {
    border-radius: 0.25em;
    margin-left: -1.5em;
    margin-right: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.25)
}
.form-check-input:checked {
    background-color: v-bind(colors.primary);
    border-color: v-bind(colors.primary);
}
.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem v-bind(colors.shadow);
    border-color: v-bind(colors.primary);
}
</style>
