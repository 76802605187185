<template>
    <button
        class="box"
        :class="{active}"
    >
        {{ title }}
        <BtnClose @click.stop="$emit('delete')"  class="delete" />
    </button>
</template>

<script>
import BtnClose from './icons/close.svg.vue';
import {computed} from "@vue/runtime-core";
import {store} from '@/store';

export default {
    components: {
        BtnClose,
    },
    props: {
        active: Boolean,
        title: String,
    },
    setup() {
      const colors = computed(() => {
        return store.getters['settings/getSettings'].settings.colors
      })
      return {colors}

    },
}
</script>

<style scoped>
.box {
    border-radius: 0.3rem;
    font-weight: 400;
    font-size: 14px;
    padding: 0.25em 0.625rem;
    margin-bottom: 0.5em;
    border: none;
    background-color: v-bind(colors.secondary);
    color: v-bind(colors.primary);
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
}

.active.box,
.box:hover {
    background-color: v-bind(colors.primary);
    color: #fff;
}

.delete {
    stroke: v-bind(colors.primary);
    cursor: pointer;
    width: 12px;
    margin-left: 10px;
    transition: 0.3s;
}

.active .delete,
.box:hover .delete {
    stroke: #fff;
}
</style>
