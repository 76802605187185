<template>
  <main
      class="main-block d-flex align-items-center">
    <div class="search-block search-block--mx">
      <div class="sSections__center-empty">
        <div class="sSections__title-empty h1">
          Недостаточно прав доступа для просмотра страницы или раздела
        </div>
        <router-link to="/">На главную</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import {store} from '@/store';
import {computed} from "vue";

export default {
  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
      colors,
    }
  },
};
</script>

<style scoped>
a {
  color: v-bind(colors.primary);
}
a:hover {
  color: v-bind(colors.hover);
}
</style>
