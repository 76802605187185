<template>
    <div class="sSearchResult__aside-group">
        <div class="fw-500 pb-3">Формат</div>
        <div class="row mb-3">
            <div
                v-for="ext in initExtensions"
                :key='ext'
                class="col-lg-4 col-md-6 mb-2">
                <label class="custom-input form-check">
                    <input
                        :value='ext'
                        :checked='modelValue.includes(ext)'
                        @change='e => changeHandler(e.target.value)'
                        class="custom-input__input form-check-input"
                        name="checkbox"
                        type="checkbox"
                    />
                    <span class="custom-input__text form-check-label">{{ ext }}</span>
                </label>
            </div>
        </div>
<!--        <a class="small fw-500">Показать все</a>-->
    </div>
</template>

<script>
import {computed} from "vue";
import {store} from '@/store';

export default {
    emits: ['update:modelValue'],
    props: {
            modelValue: {
               type: Array,
                default: () => []
            }
    },
    setup(props, {emit}) {
        const initExtensions = (['materials', 'doc','docx', 'xls', 'xlsx', 'jpg', 'pdf', 'png', 'pptx',]);
        const changeHandler = (ext) => {

            let updExtensions;
            if (props.modelValue.includes(ext)) {
                updExtensions = props.modelValue.filter(value => value !== ext);
            } else {
                updExtensions = props.modelValue.concat(ext);
            }
            emit('update:modelValue', updExtensions);
        };

      const colors = computed(() => {
        return store.getters['settings/getSettings'].settings.colors
      })
        return {
            initExtensions,
            changeHandler,
          colors,
        }
    }

};
</script>

<style scoped>
.sSearchResult__aside-group .custom-input {
    margin-bottom: 0;
}
.custom-input__input.form-check-input:checked {
  background-color: v-bind(colors.primary);
  border-color: v-bind(colors.primary);
}
.custom-input__input.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem v-bind(colors.shadow);
}
</style>
