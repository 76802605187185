<template>
    <div>
        <div class="row align-items-center">
<!--          <div v-if="modelValue || preview" class="col-auto mb-3">-->
<!--            <div-->
<!--                class="form-wrap__icon-add-wrap"-->
<!--                :style="{-->
<!--                        'background-image': `url(${src || preview})`,-->
<!--                    }"-->
<!--            ></div>-->
<!--          </div>-->
          <VButtonFileLoader
                :accept="['image/*']"
                @upload="uploadFile"
                class="col-auto mb-3"
            >
                <button class="form-wrap__btn-choose"
                >Выбрать...</button>
<!--              {{colors}}-->
            </VButtonFileLoader>
<!--            <div v-if="modelValue" class="col-auto mb-3">-->
<!--                <a class="text-danger" @click="deleteFile">Удалить</a>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import VButtonFileLoader from '@/ui/VButtonFileLoader';
import {computed} from '@vue/runtime-core';
// import settingsService from '@/services/settings.service';
import {store} from '@/store';

export default {
    components: {
        VButtonFileLoader,
    },
    props: {
        modelValue: File,
        preview: String,
        accept: String,
    },
  mounted() {
      // const store = useStore()
      // settingsService.getSettings().then((r) => {
      //   this.settingsColor.backgroundColor = r.colors.primary
      //   console.log(this.settingsColor)
      // })
    // store.getters['settings/getSettings'].then((r) => {
    //   this.settingsColor.backgroundColor = r.colors.primary
    // })
  },
  setup(props, {emit}) {
    const uploadFile = ([f]) => {
            emit('update:modelValue', f);
        };

        const deleteFile = () => {
            emit('update:modelValue', null);
        };

        const src = computed(() => {
            if (props.modelValue) {
                return window.URL.createObjectURL(props.modelValue);
            }

            return null;
        });

    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
            deleteFile,
            uploadFile,
          colors,
            src,
        };
    },
};
</script>

<style lang="scss" scoped>
.form-wrap__icon-add-wrap {
    background: var(--bs-info);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}
.text-danger {
    cursor: pointer;
}
</style>

<style scoped>
.form-wrap__btn-choose {
  background-color: v-bind(colors.primary);
  border-color: v-bind(colors.primary);
  color: #ffffff;
}
.form-wrap__btn-choose:hover {
  background-color: v-bind(colors.hover);
  border-color: v-bind(colors.hover);
  color: #ffffff;
}
.form-wrap__btn-choose:focus {
  box-shadow: 0 0 0 0.25rem v-bind(colors.shadow);
}
.form-wrap__icon-add-wrap {
  background-color: v-bind(colors.shadow);
}
</style>
