<template>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3333 2.00008H11.6666V0.666748H10.3333V2.00008H3.66659V0.666748H2.33325V2.00008H1.66659C0.933252 2.00008 0.333252 2.60008 0.333252 3.33341V14.0001C0.333252 14.7334 0.933252 15.3334 1.66659 15.3334H12.3333C13.0666 15.3334 13.6666 14.7334 13.6666 14.0001V3.33341C13.6666 2.60008 13.0666 2.00008 12.3333 2.00008ZM12.3333 14.0001H1.66659V6.66675H12.3333V14.0001ZM12.3333 5.33341H1.66659V3.33341H12.3333V5.33341Z"
            :fill="colors.primary"
        />
    </svg>
</template>

<script>
import {store} from '@/store';
import {computed} from "vue";

export default {
  setup() {
    const colors = computed(() => {
      return store.getters['settings/getSettings'].settings.colors
    })
    return {
      colors,
    }
  },
};
</script>
