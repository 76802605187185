<template>
    <div id="modal-add-field-not-required">
        <div class="form-wrap">
            <div class="h3 mb-4">Заголовок</div>

            <form @submit="submitHandle">
                <div class="form-wrap__input-wrap form-group">
                    <label
                    ><span class="form-wrap__input-title">Заголовок</span
                    ><input
                        v-model="nameValue"
                        class="form-wrap__input form-control"
                        name="text"
                        type="text"
                        placeholder="Заголовок поля"
                    />
                    </label>
                </div>
                <!-- +e.input-wrap-->
                <div class="form-wrap__input-wrap form-group">
                    <label>
                        <span class="form-wrap__input-title">
                            Содержание
                        </span >
                        <input
                            v-model="descriptionValue"
                            class="form-wrap__input form-control"

                            name="text"
                            type="text"
                            placeholder="Краткое описание поля"
                        />
                    </label>
                </div>
                <button
                    :disabled="!formMeta.valid"
                    class="btn btn-primary w-100"
                    type="submit"
                >
                    {{'Сохранить'}}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import {useField, useForm} from 'vee-validate';
import * as yup from 'yup';
import {computed} from "vue";
import {store} from '@/store';

export default {
    props: {
        config: {
            type: Object || undefined
        }
    },
    setup(props, {emit}) {

        const schema = yup.object({
            name: yup.string().required(),
            description: yup.string().required(),
        });

        const {handleSubmit, setValues, meta: formMeta} = useForm({
            validationSchema: schema
        });

        const {value: nameValue} = useField('name');
        const {value: descriptionValue} = useField('description');

        if (props.config && props.config.name && props.config.description) {
            setValues({
                name: props.config.name,
                description: props.config.description,
            });
        }

        const updateTitle = ({name, description}) => {
            emit('updateTitle', {
                name,
                description
            });
        };

        const submitHandle = handleSubmit((values) => {
            updateTitle(values);
        });

      const colors = computed(() => {
        return store.getters['settings/getSettings'].settings.colors
      })
        return {
            updateTitle,
            formMeta,
            nameValue,
            descriptionValue,
            submitHandle,
          colors,
        };
    },
};
</script>


<style scoped>
.btn-primary {
  background-color: v-bind(colors.primary);
  border-color: v-bind(colors.primary);
  color: #ffffff;
}
.btn-primary:hover {
  background-color: v-bind(colors.hover);
  border-color: v-bind(colors.hover);
  color: #ffffff;
}
.form-check-input:checked {
  background-color: v-bind(colors.primary);
  border-color: v-bind(colors.primary);
}
.form-check-input:focus {
  border-color: v-bind(colors.primary);
}
.form-check-input:focus {
  border-color: v-bind(colors.primary);
  outline: none !important;
  box-shadow: 0 0 0 0.25rem v-bind(colors.shadow) !important;
}
</style>

